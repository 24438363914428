import { Injectable, EventEmitter } from '@angular/core';
import {AuthError} from './../models/auth-error';

@Injectable()
export class MessageService {

  static errorHttp: EventEmitter<AuthError> = new EventEmitter();
  authError: AuthError;

  constructor() { 
    this.authError = new AuthError();
  }

  setErrorHttp(error){
    this.authError = error;
    MessageService.errorHttp.emit(this.authError);
  }

  getErrorHttp() {
    return MessageService.errorHttp;
  }

}

