import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { AppLayoutComponent } from './pages/layouts/app-layout/app-layout.component';

const routes: Routes = [
    {path: '', loadChildren: './pages/login/login.module#LoginModule'},
    {path: 'login', loadChildren: './pages/login/login.module#LoginModule' },
    { path: 'service-out', loadChildren: './pages/service-out/service-out.module#ServiceOutModule'},
    {
        path: '',
        canActivate: [AuthGuard],
        component: AppLayoutComponent,
        children: [
            {path: '', loadChildren: './pages/layouts/agendamento/agendamento.module#AgendamentoModule',canActivate: [AuthGuard] },
            {path: 'agendamento', loadChildren: './pages/layouts/agendamento/agendamento.module#AgendamentoModule',canActivate: [AuthGuard] },
            {path: 'config', loadChildren: './pages/layouts/config/config.module#ConfigModule',canActivate: [AuthGuard] },
            {path: 'usuario', loadChildren: './pages/layouts/usuario/usuario.module#UsuarioModule',canActivate: [AuthGuard] },
            {path: 'comunicado', loadChildren: './pages/layouts/comunicado/comunicado.module#ComunicadoModule',canActivate: [AuthGuard]  },
            {path: 'campanha', loadChildren: './pages/layouts/campanha/campanha.module#CampanhaModule',canActivate: [AuthGuard]  },
            {path: 'cliente', loadChildren: './pages/layouts/cliente/cliente.module#ClienteModule',canActivate: [AuthGuard] },
            {path: 'rel-reserva', loadChildren: './pages/layouts/relatorios/rel-reserva/rel-reserva.module#RelReservaModule',canActivate: [AuthGuard] },
            {path: 'rel-qtde-reserva', loadChildren: './pages/layouts/relatorios/rel-qtde-reserva/rel-qtde-reserva.module#RelQtdeReservaModule',canActivate: [AuthGuard] },
            {path: 'rel-comparecimento', loadChildren: './pages/layouts/relatorios/rel-comparecimento/rel-comparecimento.module#RelComparecimentoModule',canActivate: [AuthGuard] },
            /*{path: 'tipo', loadChildren: './pages/tipo-restricao/tipo-restricao.module#TipoRestricaoModule',canActivate: [AuthGuard]  },
            {path: 'csnu', loadChildren: './pages/csnu/csnu.module#CsnuModule',canActivate: [AuthGuard]},
            { path: 'not-found', loadChildren: './pages/not-found/not-found.module#NotFoundModule',canActivate: [AuthGuard] }*/
    ]},
    { path: '**', redirectTo: 'not-found' }
];   
 
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
