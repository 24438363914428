import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { UrlInterceptor } from './interceptor/url.interceptor';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { AuthService } from './services/auth.service';
import { HeaderComponent } from './pages/shared/header/header.component';
import { FooterComponent } from './pages/shared/footer/footer.component';
import { AuthGuard } from './guard/auth.guard';
import { LottieAnimationViewModule } from 'lottie-angular2';
import { LottieLoadAnimationComponent } from './commons/lottie-load-animation/lottie-load-animation.component';
import { LottieLoadAnimationService } from './services/loading.service';
import { AppLayoutComponent } from './pages/layouts/app-layout/app-layout.component';
import { MessageService } from './services/message.service';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {FuncionarioComponent} from './pages/layouts/funcionario/funcionario.component';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        LottieLoadAnimationComponent,
        AppLayoutComponent,
        FuncionarioComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        AngularFontAwesomeModule,
        AngularFireModule.initializeApp(environment.firebaseConfig, 'angular-auth-firebase'),
        AngularFireAuthModule,        
        LottieAnimationViewModule.forRoot() ],
    providers: [HttpClientModule,AuthService,AuthGuard,MessageService,LottieLoadAnimationService,
        {provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },        
        {provide: HTTP_INTERCEPTORS,
            useClass: UrlInterceptor,
            multi: true
        },
        {provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true         
        },
        { provide: LOCALE_ID, useValue: 'pt-BR' }        
    ],
    bootstrap: [AppComponent]
})
export class AppModule { 
}
