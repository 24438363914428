import { Injectable, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { LoginSocialModel } from '../models/login-social-model';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import * as jwt_decode from "jwt-decode";
import { JwtTokenService } from 'src/app/services/jwt-token.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

const url = environment.ConnectionConfig.hostAPI;

@Injectable()
export class AuthService {
  static usuarioLogado = new EventEmitter<string>();
  static idToken = new EventEmitter<string>();
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;


constructor(public _firebaseAuth: AngularFireAuth,
            private router: Router,
            protected http: HttpClient,
            private jwtTokenService: JwtTokenService,
            private localStorageService: LocalStorageService) { 
  this.user = _firebaseAuth.authState;

}
private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

signInWithTwitter() {
    return this._firebaseAuth.auth.signInWithPopup(
      new firebase.auth.TwitterAuthProvider()
    )
  }
signInWithEmailPwd(email, senha) {
    return this._firebaseAuth.auth.signInWithEmailAndPassword(email, senha)
      
  }
signInWithFacebook() {
    return this._firebaseAuth.auth.signInWithPopup(
      new firebase.auth.FacebookAuthProvider()
    )
  }
signInWithGoogle() {

    return this._firebaseAuth.auth.signInWithPopup(
      new firebase.auth.GoogleAuthProvider()
    )
  }
signUpWithEmailPwd(email, senha) {
  return this._firebaseAuth.auth.createUserWithEmailAndPassword(email, senha)
      
}

sendPasswordResetEmail(email) {
  return this._firebaseAuth.auth.sendPasswordResetEmail(email)
      
}  

validEmail(email){
  return this._firebaseAuth.auth.verifyPasswordResetCode(email);
}

signUp(email){
  
  let senha = Date.now().toString();
  return this._firebaseAuth.auth.createUserWithEmailAndPassword(email,senha);
  
}
    
isLoggedIn() {
  if (this.userDetails == null ) {
      return false;
    } else {
      return true;
    }
  }
logout() {
    return this._firebaseAuth.auth.signOut();
  }

getUser(){
  this.user.subscribe(
    (user) => {
      if (user) {

        this.userDetails = user;
        AuthService.usuarioLogado.emit(this.userDetails.displayName); 
        AuthService.idToken.emit(this.userDetails.refreshToken);

        return this.userDetails.displayName;

      }
      else {
        this.userDetails = null;
      }
    }
  );
}

getToken(){
  return this._firebaseAuth.auth.currentUser.getIdToken()
    .then(token => {
      localStorage.setItem('idToken', token);
    })
}

getTokenHeader(){
  return this._firebaseAuth.auth.currentUser.getIdToken()
  .then(token => {
      token = 'Bearer ' + token;
      let tokenHeader = new HttpHeaders({
          'Authorization': token
      });
  
      tokenHeader.append('Content-Type', 'application/json');
      let tokenOptions = ({
          headers: tokenHeader
      });
      localStorage.setItem('idToken', token);
      return tokenOptions;
  
  }).catch(error =>{
      throw error;
  });
} 
refreshToken() {
   return this.http.post<LoginSocialModel>(`${url}api/Login/Social/RefreshToken`, {
    'refreshToken': this.getRefreshToken()
  }).pipe(tap((token) => {
    this.storeJwtToken(token['accessToken']);
  }));    
  }

 storeJwtToken(jwt: string) {
  localStorage.setItem('accessToken', jwt);
}

getRefreshToken() {
  return localStorage.getItem('accessToken');
}

}
