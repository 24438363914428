import { enableProdMode, Component, ViewChild, OnInit } from '@angular/core';
import { LottieLoadAnimationComponent } from './commons/lottie-load-animation/lottie-load-animation.component';
import { LottieLoadAnimationService } from './services/loading.service';
enableProdMode();

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  title = 'Lista Restritiva';
  @ViewChild(LottieLoadAnimationComponent,null)
  lottieLoadAnimationComponent: LottieLoadAnimationComponent;
  constructor(public lottieLoadAnimationService: LottieLoadAnimationService) {
  }

  ngOnInit() {
    this.lottieLoadAnimationService.set(this.lottieLoadAnimationComponent);
  }
}