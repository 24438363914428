
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from "rxjs";
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Injectable()
export class AuthGuard implements CanActivate {

constructor(private router: Router,
            private localStorageService: LocalStorageService) 
{ }

canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if ( this.localStorageService.get('isAuth')=='true'){
            return true
        } else  {
            this.router.navigate( ["/login"] );
            return false
        }
    }
}
