import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  idToken: string;

  constructor(public auth: AuthService,
              private localStorageService: LocalStorageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('token: ', localStorage.getItem('accessToken'));
    //let auth = window.btoa('LSTRST_USVC_PRD:127443A@');

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.localStorageService.get('accessToken')}`,
      } 
    });
    return next.handle(request);
  }
}