import { Injectable } from '@angular/core';
import { LottieLoadAnimationComponent } from './../commons/lottie-load-animation/lottie-load-animation.component'

@Injectable({
  providedIn: 'root'
})
export class LottieLoadAnimationService {
  lottieLoadAnimationComponent: LottieLoadAnimationComponent;

  show() {
    if (this.lottieLoadAnimationComponent) {
      this.lottieLoadAnimationComponent.showLoad = true;
    }
  }

  hide() {
    if (this.lottieLoadAnimationComponent) {
      this.lottieLoadAnimationComponent.showLoad = false;
    }
  }

  set(lottieLoadAnimationComponent) {
    this.lottieLoadAnimationComponent = lottieLoadAnimationComponent;
  }
}