import { Component, OnInit } from '@angular/core';
import { ComunicadoService } from './../../../services/comunicado.service';
import swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';

const idEmpresa=1;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})


export class HeaderComponent implements OnInit {
  totalComunicadosDia:number=0;
  constructor(private comunicadoService: ComunicadoService,
              private authService: AuthService,
              private route: Router,
              private localStorageService: LocalStorageService) { 

    this.comunicadoService.getTotalComunicadosEmitter()
    .subscribe(total => {
        this.totalComunicadosDia=total;
    });

  }

  ngOnInit() {
    let date = new Date;
    let dataString = (`${date.getFullYear()}-${('0' + (date.getMonth() + 1)).substr(-2,2)}-${('0' + date.getDate()).substr(-2,2)}`);
    this.comunicadoService.getByEmpresaAndDia(idEmpresa,dataString)
    .subscribe(data=>{
      this.totalComunicadosDia=data.length;    
      this.comunicadoService.emitTotalComunicadosDoDia(this.totalComunicadosDia);
      if(this.totalComunicadosDia>0){
        swal.fire(
          {title:'<strong style="color: #D61216 !important; text-shadow: 5px 5px 5px rgb(223, 194, 194) !important; font-size: 40px !important;">COMUNICADOS DO DIA</strong>',
          html:`<br><p style="color: black !important; text-shadow: 1px 1px 1px rgb(223, 194, 194) !important; font-size: 20px !important;"> Existem <strong>${this.totalComunicadosDia}</strong> comunicados para hoje. Para acessá-los, clique no menu comunicado! </p><br><br>`,
          background:'rgb(253, 242, 242)',
          timer: 5000,
          showConfirmButton: false,
          //timer: 5000,
          })
      }
    
    })    
  }

  logout(){
    this.authService.logout()
      .then(data=>{
        this.localStorageService.set('isAuth',false);
        this.route.navigate(['/login']);
      })
  }

}
