import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LottieLoadAnimationService } from '../services/loading.service';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UrlInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService,
              public lottieLoadAnimationService: LottieLoadAnimationService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.lottieLoadAnimationService.show();
    let novaUrlReq = request.clone({url: request.url});//request.clone({ url: `${environment.gatewayConfig.hostAPI}${request.url}` });
    return next.handle(novaUrlReq);
  }
}
