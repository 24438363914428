import {Injectable} from '@angular/core';
import swal from 'sweetalert2';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {Router} from "@angular/router";
import {catchError} from "rxjs/internal/operators";
import { LottieLoadAnimationService } from '../services/loading.service';
import { tap } from 'rxjs/operators';
import { MessageService } from './../services/message.service';
import { AuthError } from './../models/auth-error'


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  }); 
  authError: AuthError;
  constructor(private route: Router,
              public lottieLoadAnimationService: LottieLoadAnimationService,
              private message: MessageService) { 

                this.authError = new AuthError();
  }


  /**
   * intercept all XHR request
   * @param request
   * @param next
   * @returns {Observable<A>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.lottieLoadAnimationService.hide();
        }
      },
        (err: any) => {
          this.lottieLoadAnimationService.hide();
        }));
  }


  /**
   * manage errors
   * @param err
   * @returns {any}
   */
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow

    if (err.status === 401) {
      this.toast.fire({
        type: 'error',
        background: '#FA5858',         
        title: 'Usuário não autorizado. Caso persiste o problema, contate a Arquitetura.' 
      }); 
    }
    if (err.status === 404) {
      this.toast.fire({
        type: 'error',
        background: '#FA5858',         
        title: 'Usuário ou senha inválidos. Caso persiste o problema, contate a Arquitetura.' 
      }); 
    }    
    if (err.status === 500) {
      this.toast.fire({
        type: 'error',
        background: '#FA5858',         
        title: 'Ocorreu um erro inesperado, contate a Arquitetura.' 
      }); 
    }        
    console.log(err);
    throw(err.error + ' - ' + err.message );
  }
}