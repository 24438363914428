import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHandler } from '@angular/common/http';
import {environment} from '../../environments/environment'
import { ComunicadoModel } from '../models/comunicado-model';
const url = environment.ConnectionConfig.hostAPI;
const idEmpresa=1;
@Injectable({
  providedIn: 'root'
})
export class ComunicadoService {
  static  emitirTotalComunicados: EventEmitter<number> = new EventEmitter();
  constructor(private _http: HttpClient) {

  } 

  getAll(){
    return this._http.get<ComunicadoModel[]>(`${url}api/Comunicado`);
      
  }

  getById(id){
    return this._http.get<ComunicadoModel>(`${url}api/Comunicado/${id}` );
  }

  getByEmpresa(id){
    return this._http.get<ComunicadoModel[]>(`${url}api/Comunicado/Empresa/${id}` );
  }  

  getByEmpresaAndPeriodo(id, dataInicio, dataFim){
    return this._http.get<ComunicadoModel[]>(`${url}api/Comunicado/Empresa/${id}/Inicio/${dataInicio}/Termino/${dataFim}` );
  }  

  getByEmpresaAndDia(id, data){
    return this._http.get<ComunicadoModel[]>(`${url}api/Comunicado/Empresa/${id}/Dia/${data}` );
  } 

  delete(id) {
    return this._http.delete(`${url}api/Comunicado/${id}`);
  }    

  post(comunicado: ComunicadoModel) {
    return this._http.post(`${url}api/Comunicado/`, comunicado);

  }

  put(comunicado: ComunicadoModel) {

    return this._http.put(`${url}api/Comunicado/`, comunicado);
  }  

  emitTotalComunicadosDoDia(totalComunicados: number) {
    ComunicadoService.emitirTotalComunicados.emit(totalComunicados);
  }
  
  getTotalComunicadosEmitter() {
    return ComunicadoService.emitirTotalComunicados;
  }  

}