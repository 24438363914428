import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lottie-load-animation',
  templateUrl: './lottie-load-animation.component.html',
  styleUrls: ['./lottie-load-animation.component.css']
})
export class LottieLoadAnimationComponent  {
  public lottieConfig: Object;
  private anim: any;
  private animationSpeed: number = 1;
  public showLoad: boolean;
  constructor() {
      this.lottieConfig = {
          path: 'assets/img/loading/loading_simples_icon.json',
          autoplay: true,
          loop: true,
          renderer: 'svg',
          hideOnTransparent: true,          
      };
  }

  handleAnimation(anim: any) {
      this.anim = LottieLoadAnimationComponent;
  }

  stop() {
      this.anim.stop();
  }

  play() {
      this.anim.play();
  }

  pause() {
      this.anim.pause();
  }

  setSpeed(speed: number) {
      this.animationSpeed = speed;
      this.anim.setSpeed(speed);
  }

}
